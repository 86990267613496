import React from 'react'
import {
    FiCalendar,
    FiCheckSquare,
    FiCompass,
    FiDollarSign,
    FiHome,
    FiImage,
    FiMap,
    FiSettings,
    FiUser,
    FiUsers,
} from 'react-icons/fi';


const initialState = [
    {
        title: 'Nástěnka',
        items: [
            {
                url: '/',
                icon: <FiCompass size={20}/>,
                title: 'Nástěnka',
                privileges: ['viewDashboard'],
                items: []
            },
        ]
    },
    {
        title: 'Správa poboček',
        items: [
            {
                url: '/branch',
                icon: <FiHome size={20}/>,
                title: 'Pobočky',
                privileges: ['viewBranches'],
                items: []
            },
            {
                url: '/banner',
                icon: <FiImage size={20}/>,
                title: 'Bannery',
                privileges: ['viewBanners'],
                items: []
            },
       /*     {
                url: '/career',
                icon: <FiDollarSign size={20}/>,
                title: 'Kariéra',
                privileges: ['viewCareers'],
                items: []
            },*/
            {
                url: '/event',
                icon: <FiCalendar size={20}/>,
                title: 'Události',
                privileges: ['viewEvents'],
                items: []
            },
            {
                url: '/region',
                icon: <FiMap size={20}/>,
                title: 'Regiony',
                privileges: ['viewRegions'],
                items: []
            },
            {
                url: '/milestone',
                icon: <FiCheckSquare size={20}/>,
                title: 'Milníky',
                privileges: ['viewMilestones'],
                items: []
            }
        ]
    },
    {
        title: 'Admin',
        items: [
            {
                url: '/user',
                icon: <FiUser size={20}/>,
                title: 'Uživatelé',
                privileges: ['viewUsers'],
                items: []
            },
            {
                url: '/user-group',
                icon: <FiUsers size={20}/>,
                title: 'Uživatelské skupiny',
                privileges: ['viewUserGroups'],
                items: []
            }, {
                url: '/my-profile',
                icon: <FiSettings size={20}/>,
                title: 'Nastavení',
                privileges: ['viewDashboard'],
                items: []
            },
        ]
    },
]
export default function navigationReducer(state = initialState, action) {
    switch (action.type) {
        default:
            return state
    }
}
