import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import Layouts from './layouts';
import AppRoutes from './AppRoutes';
import {connect} from 'react-redux';
import {authorize} from './pages/Login/Action';

import './css/tailwind.css';
import './css/main.css';
import './css/_components.css';

class App extends Component {

    constructor(props) {
        super(props);
    }


    componentDidMount() {
        if (this.props.location.pathname !== '/login' && this.props.location.pathname !== '/forgot-password' && this.props.location.pathname !== '/reset-password') {
            this.onRouteChanged();
            window.scrollTo(0, 0);
            this.props.dispatch(authorize(this));
        }
    }


    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged();
        }
    }


    onRouteChanged() {
        window.scrollTo(0, 0);
        this.props.dispatch(authorize(this));
    }


    render() {
        return (
            <Layouts>
                <AppRoutes/>
            </Layouts>
        );
    }
}

export default connect((store) => {
    return {
        user: store.user,
    };
})(withRouter(App));
