import React from 'react'
import * as Icon from 'react-icons/fi'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import turboLogo from '../../assets/logo_kruh.svg'


const Logo = () => {
    const dispatch = useDispatch()
    const {config, leftSidebar} = useSelector(
        state => ({
            config: state.config,
            leftSidebar: state.leftSidebar
        }),
        shallowEqual
    )
    const {name, collapsed} = {...config}
    const {showLogo} = {...leftSidebar}
    if (showLogo) {
        return (
            <div className="logo-truncate p-2 flex-row flex justify-items-start">
                <Link
                    to="/"
                    className="flex flex-row items-center justify-start space-x-2">
                    <div
                        className="mb-3 w-2/4 text-3xl font-bold tracking-wider uppercase text-primary-dark dark:text-light text-center">
                        <img src={turboLogo} alt=""/>
                    </div>
                </Link>
                <button
                    onClick={() =>
                        dispatch({
                            type: 'SET_CONFIG_KEY',
                            key: 'collapsed',
                            value: !collapsed
                        })
                    }
                    className="ml-auto mr-4 block md:hidden">
                    <Icon.FiMenu size={20}/>
                </button>
            </div>

        );
    }
    return null
}

export default Logo
