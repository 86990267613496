import React from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import Item from './item'
import Logo from './logo'
import '../../css/components/left-sidebar-1.css'
import AuthorizedFunction from "../utilities/AuthorizedFunction";

const Sidebar = () =>
{
	const {navigation} = useSelector(
		(state) => ({
			navigation: state.navigation,
		}),
		shallowEqual
	)
	return (

		<div className="left-sidebar left-sidebar-1">
			<Logo/>
			{navigation.map((menu, i) => (
				<div key={i}>
					<div className="left-sidebar-title">
						<span>{menu.title}</span>
					</div>
					<ul>
						{menu.items.map((l0, a) =>
						{
							if (l0.items.length >0)
							{
								return <li key={a} className="l0">
									<Item {...l0} />
									<ul>
										{l0.items.map((l1, b) =>
										{
											if (AuthorizedFunction(l1.privileges))
											{
												return <li key={b} className="l1">
													<Item {...l1} />
													<ul>
														{l1.items.map((l2, c) =>
														{
															if (AuthorizedFunction(l2.privileges))
															{
																return <li key={c} className="l2">
																	<Item {...l2} />
																	<ul>
																		{l2.items.map((l3, d) =>
																		{
																			if (AuthorizedFunction(l3.privileges))
																			{
																				return <li key={d} className="l3">
																					<Item {...l3} />
																					<ul>
																						{l3.items.map((l4, e) =>
																						{
																							if (AuthorizedFunction(
																								l4.privileges))
																							{
																								return <li key={e}
																										   className="l4">
																									<Item {...l4} />
																								</li>
																							}
																							return ''
																						})}
																					</ul>
																				</li>
																			}
																			return ''
																		})}
																	</ul>
																</li>
															}
															return ''
														})}
													</ul>
												</li>
											}
											return ''
										})}
									</ul>
								</li>
							}else if (AuthorizedFunction(l0.privileges)){
								return <li key={a} className="l0">
									<Item {...l0} />
									<ul>
										{l0.items.map((l1, b) =>
										{
											if (AuthorizedFunction(l1.privileges))
											{
												return <li key={b} className="l1">
													<Item {...l1} />
													<ul>
														{l1.items.map((l2, c) =>
														{
															if (AuthorizedFunction(l2.privileges))
															{
																return <li key={c} className="l2">
																	<Item {...l2} />
																	<ul>
																		{l2.items.map((l3, d) =>
																		{
																			if (AuthorizedFunction(l3.privileges))
																			{
																				return <li key={d} className="l3">
																					<Item {...l3} />
																					<ul>
																						{l3.items.map((l4, e) =>
																						{
																							if (AuthorizedFunction(
																								l4.privileges))
																							{
																								return <li key={e}
																										   className="l4">
																									<Item {...l4} />
																								</li>
																							}
																							return ''
																						})}
																					</ul>
																				</li>
																			}
																			return ''
																		})}
																	</ul>
																</li>
															}
															return ''
														})}
													</ul>
												</li>
											}
											return ''
										})}
									</ul>
								</li>
							}
							return ''
						})}
					</ul>
				</div>
			))}
		</div>
	)
}

export default Sidebar
