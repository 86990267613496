import axios from "axios";

export const ApiAxios = axios.create({
	withCredentials: true,
	baseURL: process.env.REACT_APP_ENDPOINT,
	headers: {
		'Content-Type': 'application/json'
		,
	},
    credentials: 'include'
});
