import React, {lazy, Suspense} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import PrivateRoute from "./components/utilities/PrivateRoute";
import Loader from "./components/loader/loader";

const Dashboard = lazy(() => import('./pages/Dashboard/Dashboard'));

//  User
const User = lazy(() => import( './pages/User/User'));
const UserCreate = lazy(() => import( './pages/User/Create'));
const UserEdit = lazy(() => import( './pages/User/Edit'));

//  User Group
const UserGroup = lazy(() => import( './pages/UserGroup/UserGroup'));
const UserGroupCreate = lazy(() => import( './pages/UserGroup/Create'));
const UserGroupEdit = lazy(() => import( './pages/UserGroup/Edit'));

//  Custom Turbo pizza routes
const Banner = lazy(() => import( './pages/Banner/Banner'));
const BannerCreate = lazy(() => import( './pages/Banner/Create'));
const BannerEdit = lazy(() => import( './pages/Banner/Edit'));

//  Branches
const Branch = lazy(() => import( './pages/Branch/Branch'));
const BranchCreate = lazy(() => import( './pages/Branch/Branch'));
const BranchEdit = lazy(() => import( './pages/Branch/Edit'));

//  Regions
const Region = lazy(() => import( './pages/Region/Region'));
const RegionCreate = lazy(() => import( './pages/Region/Create'));
const RegionEdit = lazy(() => import( './pages/Region/Edit'));

//  Milestones
const Milestone = lazy(() => import( './pages/Milestone/Milestone'));
const MilestoneCreate = lazy(() => import( './pages/Milestone/Create'));
const MilestoneEdit = lazy(() => import( './pages/Milestone/Edit'));

//  Career
const Career = lazy(() => import( './pages/Career/Career'));
const CareerCreate = lazy(() => import( './pages/Career/Create'));
const CareerEdit = lazy(() => import( './pages/Career/Edit'));

//  Career
const Event = lazy(() => import( './pages/Event/Event'));
const EventCreate = lazy(() => import( './pages/Event/Create'));
const EventEdit = lazy(() => import( './pages/Event/Edit'));

const Profile = lazy(() => import( './pages/Profile/Profile'));
const PrimaryLogin = lazy(() => import( './pages/Login/Login'));
const Logout = lazy(() => import('./pages/Login/Logout'));
const Reset = lazy(() => import( './pages/ResetPassword/Reset'));
const ResetPassword = lazy(() => import( './pages/ResetPassword/ResetPassword'));
const Settings = lazy(() => import( './pages/Settings/Settings'));

const AppRoutes = () =>
{
	return (
		<Suspense fallback={<Loader/>}>
			<Switch>
				{/*<Route exact path="/" component={Dashboard}/>*/}
				<PrivateRoute exact path="/" privileges={['viewDashboard']} component={Dashboard}/>

				<PrivateRoute exact path="/user" privileges={['viewUsers']} component={User}/>
				<PrivateRoute path="/user/create" privileges={['insertUser']} component={UserCreate}/>
				<PrivateRoute path="/user/edit/:userId" privileges={['editUser']} component={UserEdit}/>

				<PrivateRoute exact path="/user-group" privileges={['viewUserGroups']} component={UserGroup}/>
				<PrivateRoute path="/user-group/create" privileges={['insertUserGroup']} component={UserGroupCreate}/>
				<PrivateRoute path="/user-group/edit/:userGroupId" privileges={['editUserGroup']} component={UserGroupEdit}/>

				<PrivateRoute exact path="/banner" privileges={['viewBanners']} component={Banner}/>
				<PrivateRoute path="/banner/create" privileges={['insertBanner']} component={BannerCreate}/>
				<PrivateRoute path="/banner/edit/:bannerId" privileges={['editBanner']} component={BannerEdit}/>

                <PrivateRoute exact path="/branch" privileges={['viewBranches']} component={Branch}/>
                <PrivateRoute path="/branch/create" privileges={['insertBranch']} component={BranchCreate}/>
                <PrivateRoute path="/branch/edit/:branchId" privileges={['editBranch']} component={BranchEdit}/>

                <PrivateRoute exact path="/region" privileges={['viewRegions']} component={Region}/>
                <PrivateRoute path="/region/create" privileges={['insertRegion']} component={RegionCreate}/>
                <PrivateRoute path="/region/edit/:regionId" privileges={['editRegion']} component={RegionEdit}/>

                <PrivateRoute exact path="/milestone" privileges={['viewMilestones']} component={Milestone}/>
                <PrivateRoute path="/milestone/create" privileges={['insertMilestone']} component={MilestoneCreate}/>
                <PrivateRoute path="/milestone/edit/:milestoneId" privileges={['editMilestone']} component={MilestoneEdit}/>

                <PrivateRoute exact path="/career" privileges={['viewCareers']} component={Career}/>
                <PrivateRoute path="/career/create" privileges={['insertCareer']} component={CareerCreate}/>
                <PrivateRoute path="/career/edit/:careerId" privileges={['editCareer']} component={CareerEdit}/>

                <PrivateRoute exact path="/event" privileges={['viewEvents']} component={Event}/>
                <PrivateRoute path="/event/create" privileges={['insertEvent']} component={EventCreate}/>
                <PrivateRoute path="/event/edit/:eventId" privileges={['editEvent']} component={EventEdit}/>


                <Route exact path="/my-profile" component={Profile}/>
				<Route exact path="/notifications" component={Notification}/>

				<Route exact path="/forgot-password" component={Reset}/>
				<Route exact path="/reset-password" component={ResetPassword}/>

				<PrivateRoute exact path="/settings" privileges={['viewSettings']} component={Settings}/>

				<Route path="/login">
					<PrimaryLogin/>
				</Route>
				<Route path="/logout" component={Logout}/>
				<Redirect to="/"/>
			</Switch>
		</Suspense>
	)
}
export default AppRoutes
