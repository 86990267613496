import React from 'react'
import {Link, useHistory} from 'react-router-dom'
import {FiLogIn, FiUser} from 'react-icons/fi'
import {ApiAxios} from "../../axios/axiosConfig";
import {toast} from "react-toastify";

const AccountLinks = () =>
{
	const history = useHistory();

	const logout = () =>
	{
		ApiAxios.get('/v1/logout')
			.then(res =>
			{
				history.push("/logout");
				toast.success("Právě jste byli úspěšně odhlášeni.");
			})
			.catch(err =>
			{
				toast.error("Nastala chyba při odhlašování.");
			});
	};

	const items = [
		{
			url: '/my-profile',
			icon: <FiUser size={18} className="stroke-current"/>,
			name: 'Můj profil',
			badge: null
		}
	]

	return (
		<div className="flex flex-col w-full">
			<ul className="list-none">
				{items.map((item, i) => (
					<li key={i} className="dropdown-item">
						<Link
							to={item.url}
							className="flex flex-row items-center justify-start h-10 w-full px-2">
							{item.icon}
							<span className="mx-2">{item.name}</span>
							{item.badge && (
								<span
									className={`uppercase font-bold inline-flex text-center p-0 leading-none text-2xs h-4 w-4 inline-flex items-center justify-center rounded-full ${item.badge.color} ml-auto`}>
                  {item.badge.number}
                </span>
							)}
						</Link>
					</li>
				))}
				<li className="dropdown-item">
					<button
						onClick={() => logout()}
						className="flex flex-row items-center justify-start h-10 w-full px-2">
						<FiLogIn size={18} className="stroke-current"/>
						<span className="mx-2">Odhlásit se</span>
					</button>
				</li>
			</ul>
		</div>
	)
}

export default AccountLinks
