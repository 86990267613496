import {useSelector} from "react-redux";

export default function AuthorizedFunction(privileges)
{
	const auth = useSelector(
		state => ({
			permission: state.permission,
		})
	);

	const isAuthorized = () =>
	{
		try
		{
			if (auth.permission.permission === null)
			{
				return true;
			}
			if (auth.permission.hasOwnProperty(privileges))
			{
				if (auth.permission[privileges] === true)
				{
					return true;
				}
			}
			return false;
		}
		catch (error)
		{
			return false;
		}
	};
	return isAuthorized();
}
