import React from 'react';
import ReactDOM from 'react-dom';
import {Provider as ReduxProvider} from 'react-redux';
import store from './redux/store';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter, Router} from "react-router-dom";
import {createBrowserHistory} from "history";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const history = createBrowserHistory();

ReactDOM.render(
    <React.StrictMode>
        <ReduxProvider store={store}>
            <ToastContainer
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover={false}
            />
            <BrowserRouter>
                <Router history={history}>
                    <App/>
                </Router>
            </BrowserRouter>
        </ReduxProvider>
    </React.StrictMode>,
    document.getElementById('app')
)

serviceWorker.unregister()
