import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {useSelector} from "react-redux";

const PrivateRoute = ({component: Comp, privileges, path, ...rest}) =>
{

	const auth = useSelector(
		state => ({
			permission: state.permission,
		})
	);

	const isAuthorized = () =>
	{
		try
		{
			if (auth.permission.permission === null)
			{
				return true;
			}
			if (auth.permission.hasOwnProperty(privileges))
			{
				if (auth.permission[privileges] === true)
				{
					return true;
				}
			}
			return false;
		}
		catch (error)
		{
			return false;
		}
	};

	return (
		<Route
			path={path}
			{...rest}
			render={(props) =>
				isAuthorized() ? (
					<Comp {...props} />
				) : (
					<Redirect
						to={{
							pathname: "/",
							state: {
								prevLocation: path,
							},
						}}
					/>
				)
			}
		/>

	);
};

export default PrivateRoute;
