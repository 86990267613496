import React from 'react'
import moment from "moment";
import {Link} from "react-router-dom";

const List2 = ({items, setNotificationRead}) =>
{
	return (
		<div className="flex flex-row flex-wrap">
			<Link to={`/notifications`} className={'w-full flex items-center justify-center dropdown-item p-2'}>
				<div className="w-full">
					<div
						className="flex items-center justify-center dropdown-item p-2">
						<div>
							<div className="text-sm font-bold">Ukázat vše</div>
						</div>
					</div>
				</div>
			</Link>
			{items.map((item, i) => (
				<Link to={`${item.relatedTo === 'RATING' ? '/rating/detail/' + item.rating :
					'/call/comment/' + item.call}`} key={i} className="w-full"
					  onClick={() => setNotificationRead(item._id)}>
					<div key={i} className="w-full">
						<div
							className="flex items-center justify-start dropdown-item p-2"
							key={i}>
							<div className="ml-2">
								<div className="text-sm font-bold">{item.action} {moment(
									item.createdAt)
									.local()
									.format("DD/MM/YYYY HH:mm")}</div>
								<div className="text-xs">č. {item.relatedTo === 'RATING' ? item.rating :
									item.call}</div>
							</div>
						</div>
					</div>
				</Link>
			))}
		</div>
	)
}

export default List2
