import React, {useEffect, useRef, useState} from 'react'
import {FiBell} from 'react-icons/fi'
import List2 from './list-2'
import {ApiAxios} from "../../axios/axiosConfig";
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";

const Dropdown = () =>
{
	const [hidden, setHidden] = useState(true)
	const notification = useSelector(state => state.notification);

	const buttonRef = useRef(null)
	const dropdownRef = useRef(null)
	const history = useHistory();

	useEffect(() =>
	{
		const handleClickOutside = (event) =>
		{
			if (
				hidden ||
				buttonRef.current.contains(event.target) ||
				dropdownRef.current.contains(event.target)
			)
			{
				return false
			}
			setHidden(!hidden)
		}
		document.addEventListener('mousedown', handleClickOutside)
		return () =>
		{
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [hidden, dropdownRef, buttonRef])

	const handleDropdownClick = () =>
	{
		setHidden(!hidden)
	}
	const setNotificationRead = (id) =>
	{
		ApiAxios.post(`/v1/notification/read`, {id: id}).catch(err =>
		{
			history.push("/dashboard");
			toast.error("Chyba při načítání počtu notifikací!");
		});
	}
	return (
		<div className="hidden lg:flex relative">
			<button
				ref={buttonRef}
				onClick={handleDropdownClick}
				className="flex items-center justify-center h-16 w-12 relative">
				<FiBell size={18}/>
				{notification && notification.notificationsCount !== 0 ?
					(
						<span
							className="absolute uppercase font-bold inline-flex text-center p-0 leading-none text-2xs h-4 w-4 inline-flex items-center justify-center rounded-full bg-red-500 text-white"
							style={{top: 14, right: 8}}>
				{notification && notification.notificationsCount}
					</span>
					)
					:
					null
				}
			</button>
			<div ref={dropdownRef}
				 className={`dropdown absolute top-0 right-0 mt-16 ${hidden ? '' : 'open'}`}>
				<div className="dropdown-content w-64 bottom-start">
					<List2 items={(notification && notification.notificationsData)}
						   setNotificationRead={setNotificationRead}/>
				</div>
			</div>
		</div>
	)
}

export default Dropdown
