import React from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {FiHeadphones, FiMenu} from 'react-icons/fi'
import Dropdown3 from './dropdown-3'
import Dropdown5 from './dropdown-5'
import '../../css/components/navbar.css'
import AuthorizedElement from "../utilities/AuthorizedElement";
import {ApiAxios} from "../../axios/axiosConfig";
import {toast} from "react-toastify";

const Navbar = () =>
{
	const {config} = useSelector(
		state => ({
			config: state.config
		}),
		shallowEqual
	)
	let {collapsed} = {...config}
	const dispatch = useDispatch();
	const onClick = (collapsed) =>
	{
		ApiAxios.put(`/v1/user/settings/sidebar`, {
			"collapsed": !collapsed,
		}).then(res =>
		{
			dispatch({
				type: 'SET_CONFIG',
				config: {
					collapsed: !collapsed
				}
			});
		}).catch(err =>
		{
			toast.error("Chyba při změně postranního panelu", {
				className: 'error-message',
				position: toast.POSITION.TOP_RIGHT,
			});
		});
	}
	return (
		<div className="navbar navbar-1 border-b  ">
			<div className="navbar-inner w-full flex items-center justify-start">
				<button
					onClick={() => onClick(collapsed)}
					className="mx-4">
					<FiMenu size={20}/>
				</button>
				{/*<Search/>*/}
				<AuthorizedElement privileges={['insert_call']}>
					{/*<Dropdown6/>*/}
					<a href={`/call/create`}
						  className="btn btn-default btn-rounded btn-icon bg-green-500 hover:bg-green-600 text-white space-x-1">
						<FiHeadphones className="stroke-current mr-2"/>
						<span className="mr-2">Nový hovor</span>
					</a>
				</AuthorizedElement>
				<span className="ml-auto"></span>
				{/*<Dropdown4/>*/}
				<Dropdown3/>
				<Dropdown5/>
			</div>
		</div>
	)
}

export default Navbar
