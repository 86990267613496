import {ApiAxios} from "../../axios/axiosConfig";
import {toast} from "react-toastify";


export function userLogin(data, _this)
{

	return function (dispatch)
	{
		console.log(_this);

		ApiAxios.post('/v1/login', data, {
			headers: {
				"LoginToken": process.env.REACT_APP_LOGIN_TOKEN
			}
		}).then(res =>
		{
			if (res.data)
			{
				dispatch({type: "USER_LOAD_START"});
				ApiAxios.post('/v1/auth').then(response =>
				{
					console.log('Authorized 👍');
					window.location.href = "/";
				}).catch(err =>
				{
					toast.error("Chyba při pokusu o přihlášení.");
					console.error(err);
					dispatch({type: "USER_LOGOUT"});
				})
			}
			else
			{
				toast.error("Zkontrolujte prosím Vaše údaje.");
				console.info("Neplatné údaje.");
			}
		}).catch((err) =>
		{
			toast.error("Zkontrolujte prosím Vaše údaje.");
			console.error(`Chyba: ${err}`);
			dispatch({type: "USER_LOGOUT"});
			//window.location.href = "/login";
		});
	}
}

export function authorize(_this)
{
	return function (dispatch)
	{
		dispatch({type: "USER_LOAD_START"});
		ApiAxios.post('/v1/auth').then(response =>
		{
			console.log('Authorized 👍');
			dispatch({
				type: "SET_PERMISSION", permission: response.data.data.rights
			})
			dispatch({type: "USER_LOAD_DONE", data: response.data.data, privilege: response.data.rights})
			dispatch({
				type: "SET_PALETTE", palette: {
					background: response.data.data.config.background,
					leftSidebar: response.data.data.config.leftSidebar,
					navbar: response.data.data.config.navbar,
					rightSidebar: response.data.data.config.rightSidebar
				}
			})
			dispatch({
				type: 'SET_CONFIG',
				config: {
					collapsed: response.data.data.config.collapsed
				}
			})

		}).catch(err =>
		{
			dispatch({type: "USER_LOGOUT"});
			window.location.href = "/login";
		})
	}
}

export function loginAuth(_this)
{
	return function (dispatch)
	{
		dispatch({type: "USER_LOAD_START"});
		ApiAxios.post('/v1/auth').then(response =>
		{
			console.log('Authorized 👍');
			dispatch({
				type: "SET_PERMISSION", permission: response.data.data.rights
			})
			dispatch({type: "USER_LOAD_DONE", data: response.data.data, privilege: response.data.rights})
			dispatch({
				type: "SET_PALETTE", palette: {
					background: response.data.data.config.background,
					leftSidebar: response.data.data.config.leftSidebar,
					navbar: response.data.data.config.navbar,
					rightSidebar: response.data.data.config.rightSidebar
				}
			})
			dispatch({
				type: 'SET_CONFIG',
				config: {
					collapsed: response.data.data.config.collapsed
				}
			})
			/*ApiAxios.get(`/v1/notification/navbar`).then(res =>
			{
				dispatch({
					type: "SET_NOTIFICATION", notificationsCount: res.data.totalItems,
					notificationsData: res.data.data
				})
			}).catch(err =>
			{
				toast.error("Chyba při načítání počtu notifikací!");
			});*/
			window.location.href = "/"
		}).catch(err =>
		{
			console.error(err)
			dispatch({type: "USER_LOGOUT"});
		})
	}
}
