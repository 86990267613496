import {applyMiddleware, createStore} from "redux";

import {createLogger} from "redux-logger";
import thunk from "redux-thunk";
import reducer from "./reducer"

var createStoreWithMiddleware;
if (process.env.NODE_ENV !== 'production') {
    createStoreWithMiddleware
        = applyMiddleware(
        thunk,
        createLogger()
    )(createStore);
}
else{
    createStoreWithMiddleware
        = applyMiddleware(
        thunk,
    )(createStore);
}

//const middleware = applyMiddleware(promise(), thunk, createLogger());

const store = createStoreWithMiddleware(
    reducer, // Loading done here
);

export default store;
